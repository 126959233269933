<template>
  <div class="detail-drawer" v-if="singleReceivable">
    <div class="d-flex justify-space-between align-center">
      <p class="title pb-3">Receivable Detail</p>
      <v-btn @click="close" icon color="primary">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="section__div">
      <p class="headerText">customer</p>
      <p class="valueText linkText" @click.prevent="openSingleCustomer">
        {{ customerEmail }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Amount</p>
      <p class="amountText">
        {{ singleReceivable.total | currency(currencyNoSymbol) }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Due Date</p>
      <p class="valueText">{{ singleReceivable.invoice.due_date }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">Paid</p>
      <p class="valueText">
        {{ singleReceivable.paid | currency(currencyDefault) }}
      </p>
    </div>
    <div class="section__div">
      <p class="headerText">Expected Payment</p>
      <p class="valueText">{{ expectedPayment | currency(currencyDefault) }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">Invoice Ref.</p>
      <p class="valueText">{{ singleReceivable.invoice.invoicenumber }}</p>
    </div>
    <!-- <div class="section__div">
      <p class="headerText pb-2">Category</p>
      <v-select outlined hide-details="auto"></v-select>
    </div> -->
    <div class="section__div">
      <p class="headerText pt-5 pb-4">Payment Timeline</p>
      <div class="content">
        <div class="card py-10" v-if="singleReceivable.timeline.length === 0">
          <p class="card__value pb-0">
            No payment received for this transaction
          </p>
        </div>
        <div v-for="(card, i) in singleReceivable.timeline" :key="i" v-else>
          <div class="card">
            <p class="card__value">
              {{ card.amount | currency(currencyDefault) }}
            </p>
            <p class="card__text"><b>Source: </b>{{ card.counterparty }}</p>
            <p class="card__text"><b>Date: </b>{{ card.date | moment("l") }}</p>
            <!-- <p class="card__text"><b>Fee: </b>{{ card.fee }}</p> -->
            <p class="card__text text-truncate">
              <b>Narration: </b>
              <br />
              <span>{{ card.narration }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currencyFilter from "@/mixins/currencyFilter.js";
import { mapGetters } from "vuex";
export default {
  name: "ReceivableDetails",

  props: {
    receivableId: {
      type: [String, Number],
      required: true,
    },
  },
  mixins: [currencyFilter],
  methods: {
    // close drawer
    close() {
      this.$emit("close");
    },

    // open single customer
    openSingleCustomer() {
      this.$router.push({
        name: "contact.customer",
        params: { id: this.customerId },
      });
    },
  },
  computed: {
    //vuex getters
    ...mapGetters({
      receivables: "receivables/getAllReceivables",
      organizationToken: "organizations/OrganToken",
      singleReceivable: "receivables/getReceivables",
    }),

    // calculate expected payment  from singleReceivable.total and singleReceivable.paid
    expectedPayment() {
      return this.singleReceivable.total - this.singleReceivable.paid;
    },

    // filter receivables by receivableId to get customer email
    customerEmail() {
      return this.receivables.filter(
        (receivable) => receivable?.id === this.receivableId
      )[0]?.customer?.email;
    },

    // filter receivables by receivableId to get customer id
    customerId() {
      return this.receivables.filter(
        (receivable) => receivable.id === this.receivableId
      )[0].customer?.id;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

.detail-drawer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 40px;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .section__div {
    padding-bottom: 10px;

    .headerText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #8f96a1;
      display: block;
    }

    .valueText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }

    .amountText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }
  }
}

.section__div {
  padding-bottom: 10px;

  .headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #8f96a1;
    display: block;
  }

  .valueText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .amountText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .linkText {
    cursor: pointer;
  }
}

.details-wrapper {
  padding: 20px;
}

.content::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-columns: repeat(2, minmax(0, 1fr));
  // flex: 1;
  // overflow: auto;
  // margin-top: 5px;
  // &__action {
  //   padding: 0px 5px;
  //   cursor: pointer;
  //   margin: 20px 0px;
  //   &:hover {
  //     span {
  //       color: #d7a47b;
  //     }
  //   }
  //   img {
  //     width: 30px;
  //     height: 30px;
  //     display: block;
  //     margin: auto;
  //   }
  //   span {
  //     display: block;
  //     text-align: center;
  //     margin-top: 10px;
  //     color: #7f919b;
  //   }
  //   .coming-soon {
  //     font-family: "Inter";
  //     opacity: 0.9;
  //     margin-top: 1px;
  //     color: #d7a47b;
  //   }
  // }

  .card {
    background: #f8f9fc;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px 20px;

    &__value {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
    }

    &__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      color: #8f96a1;
      opacity: 0.8;
    }
  }
}
</style>
